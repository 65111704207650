.hero-section {
  width: 100%;
  position: fixed;
  background-color: #f1f2f2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
}
.blog-detail-hero-section {
  width: 100%;
  position: static;
  background-color: #f1f2f2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: scroll;
}

/* .left-box { background-color: #FCF1CC; padding: 80px 38px; width: 240px;top: 0; left: 0; bottom: 0; overflow: hidden; overflow-y: scroll; z-index: 99; position: fixed;} */
.left-box {
  margin-left: unset;
  position: fixed;
  width: 240px;
  left: -300px;
  bottom: 0;
  top: 0;
background-color: #F6F6FB;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 1000;
  transition: ease 0.5s;
}
.hero-section .left-overlay {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
  transition: ease 0.5s;
}
@media (min-width: 1024px) {
  .hero-section .left-box {
    position: static;
    margin-left: 0px;
    padding: 80px 38px;
    width: 400px;
    background-color: transparent;
  }
}
.left-box::-webkit-scrollbar {
  width: 1px;
}
.left-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(212, 212, 212);
  border-radius: 10px;
}
.left-box::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 20px;
}
.left-box::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.left-box .add-content-title {
  margin-bottom: 20px;
}
.left-box .add-content-title a {
  color: #000000;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}
.left-box .add-content-list {
  margin-bottom: 0px;
  padding: 6px 10px;
  border-radius: 6px;
}

.active-header {
  background-color: #EFC348;
  border-radius:10px;
  padding: 10px 20px;
  color: white !important;
}
.social-media-desktop{

}
.social-media-desktop a {
  text-decoration: none;
  color: #000000;
  padding: 8px 14px !important;
  font-size: 18px;
  font-weight: 500;
}
.social-media-desktop a:hover {
  color: #000000;
}
.left-box .add-content-list a {
  color: #656052;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: all 0.5s;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 15px;
}
.left-box .add-content-list a i {
  margin-right: 10px;
  font-size: 20px;
  transition: all 0.5s;
}
.fa-book{
  font-size: 22px !important;
  margin-left: -1px;
}
@media (max-width: 480px) {
  .left-box {
  
    height: 100%;
    padding: 90px 0px 90px 16px;
  }
}

.add-content-page-title {
  padding: 4px 20px;
  font-size: 30px;
  margin: 0;
  color: #fff;
}
.category-content-title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.category-content-title select {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  background-color: transparent;
  outline: none;
  color: #000000;
  letter-spacing: 1px;
  margin: 0;
}
.category-content-title select option {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  background-color: #ffffff;
  outline: none;
  color: #000000;
  letter-spacing: 1px;
  margin: 0;
}
.category-sidebar-title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  color: #000000;
  letter-spacing: 1px;
  margin: 0;
}

.left-side-icon {
  margin-right: 10px;
}
.center-box {
  height: 100%;
  width: 100%;
  padding: 110px 30px 110px 30px;
  overflow-x: hidden;
}
.center-box .path-name {
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #000;
  /* background-color: #fff; */
  padding: 5px 0px;
  cursor: pointer;
}
.center-box .path-name-bold {
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #000;
  /* background-color: #fff; */
  padding: 5px 0px;
  cursor: auto;
}
.center-box::-webkit-scrollbar {
  display: none;
}
.center-card {
  display: flex;
  gap: 22px;
  margin-bottom: 28px;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px;
}
.center-card .center-img img {
  height: 80px;
  width: 80px;
}
.center-card .center-card-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.center-card p {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px;
  font-family: "Poppins";
}
.center-btn {
  margin-top: 20px;
  border: none;
  background-color: transparent;
  margin-bottom: 10px;
}
.center-btn a {
  text-decoration: none;
  background: rgba(255, 207, 22, 0.4);
  border: 2px solid rgba(255, 207, 22, 0.4);
  transition: ease 0.5s;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  padding: 8px 22px;
  border-radius: 5px;
}
.center-btn a:hover {
  background-color: #000000;
  color: #ffffff !important;
  border: 2px solid #000000;
}

@media (min-width: 1024px) {
  .center-card {
    display: flex;
    gap: 22px;
    margin-bottom: 28px;
    flex-direction: row;
  }
  .center-box {
    height: 100%;
    width: 100%;
    padding: 80px 30px 110px 30px;
    overflow-x: hidden;
  }
}

.right-box {
  background-color: #f5f0f0;
  height: 100%;
  padding: 80px 20px;
  float: right;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 50%;
}
.right-box .right-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.right-box .suggest-box {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 6px;
}
.right-card {
  display: flex;
  gap: 22px;
  margin-bottom: 28px;
  flex-direction: column;
  border-bottom: 0.5px solid #dbe5eb;
  padding-bottom: 20px;
}
.right-card .right-card-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  max-width: 197px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.right-card p {
  color: #8c8c8c;
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px;
  font-family: "Poppins";
}

@media (min-width: 1024px) {
  .right-card {
    display: flex;
    gap: 22px;
    margin-bottom: 28px;
    flex-direction: row;
  }
}
@media (max-width: 780px) {
  .right-box {
    display: none;
  }
}

/* Review Page CSS */

.review-title {
  font-weight: 700;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 43px;
  margin-bottom: 11px;
}
.review-box {
  border: 1px solid #b3b8ba;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 18px;
}
.rating-star {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-right: 10px !important;
}
.rating-star li {
  display: inline-block;
  padding: 0 4px 0 0;
}
.review-card-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  padding-left: 8px;
  font-family: "Poppins", sans-serif;
}
.review-card-title-p{
    font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #4f4e4e;
  margin: 0;
  padding-left: 8px;
  font-family: "Poppins", sans-serif;
}
.review-card-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.review-card-text-last {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.review-bottom-box {
  bottom: 0;
  position: fixed;
  width: 100%;
}
.review-bottom {
  bottom: 0;
  position: sticky;
  background-color: #ffffff;
  padding: 20px 0;
  max-width: 1040px;
  border-radius: 10px;
  padding: 20px;
}
@media (max-width: 1440px) {
  .review-bottom {
    max-width: 732px;
  }
}
@media (max-width: 1024px) {
  .review-bottom {
    max-width: 466px;
  }
}
@media (max-width: 768px) {
  .review-bottom {
    max-width: 710px;
  }
}
@media (max-width: 480px) {
  .review-bottom {
    max-width: 390px;
  }
}
.review-bottom-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #000000;
}
.review-bottom-text {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin: 0 0 6px 0;
}
.review-bottom-write-box {
  position: relative;
  width: 100%;
}
.review-bottom-write-box textarea{
  width: 100%;
  border-radius: 10px;
  padding: 15px;
}
.review-write-input {
  padding: 19px 22px;
  background-color: transparent;
  font-family: "Poppins";
  position: relative;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  outline: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #bdc0c2;
  border-radius: 10px;
}
.review-input-img {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0%, -50%);
}

/* Customer Review CSS */

.customer-review-text {
  margin: 20px 0;
}
.customer-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
.customer-review-text li {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 5px;
}
.customer-review-title {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 20px;
}
.customer-card {
  display: flex;
  gap: 20px;
}
.customer-card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.five-star-rating {
  width: 300px;
  margin-bottom: 13px;
}
.rangeSlider {
  border: 1px solid #e0cccc !important;
  padding: 0 !important;
  background-color: #f5f0f0 !important;
  color: #ffd233 !important;
  border-radius: 0 !important;
  margin: 0 13px;
  height: 20px !important;
}
.star-num,
.star-per {
  width: 66px;
}

/* Question Page Css */

.question-card {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 25px;
  margin-bottom: 10px;
}
.question-card a {
  text-decoration: none;
}
.question-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 21px;
}
.question-card ul li a {
  text-decoration: none;
  color: rgba(0, 0, 128, 1);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.question-time {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 12px;
  line-height: 1px;
  color: rgba(84, 84, 84, 1);
  margin-top: -15px;
}
.question-card-title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  /* margin-top: 16px; */
  /* padding-bottom: 10px; */
  /* margin-top: 20px; */

}
.question-card-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5d5d5d;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 2px solid #dbe5eb;
  text-align: left;
}
.like {
  margin-right: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.like i {
  margin-right: 6px;
  color: rgba(84, 84, 84, 1);
}
.comment {
  margin-right: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.comment i {
  margin-right: 6px;
  color: rgba(84, 84, 84, 1);
}

/* Answer Page Css */

.answer-box ul li a {
  text-decoration: none;
  color: rgba(0, 0, 128, 1);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.answer-card-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-top: 16px;
  padding-bottom: 20px;
  border-bottom: 2px solid #dbe5eb;
}
.comment-card {
  margin: 10px 0 40px 0;
}
.comment-content {
  position: relative;
  padding: 7px 10px;
  background-color: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 15px;
}
.comment-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.comment-text {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding-top: 10px;
}
.comment-content .bi-chevron-left {
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translate(0, -50%);
  color: #ded3d3;
  font-size: 20px;
}
.comment-answer {
  margin: 10px 0 20px 0;
}
.comment-answer input {
  margin-right: 0px;
  padding: 17px 10px;
  outline: none;
  position: relative;
}
.comment-answer .send-btn {
  background: #2ca9d9;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  padding: 0 9px;
  transition: ease 0.5s;
}
.comment-answer .send-btn:hover {
  background-color: #000000;
}
.writer-box {
  border-bottom: 0.5px solid #a0a6a9;
  margin-top: 4px;
  padding-bottom: 6px;
}
.writer-detail {
  margin-left: 10px;
}
.writer-detail h2 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.writer-detail .writer-flag {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #908f96;
}
.writer-box .view-btn {
  color: #d49a0f;
  border: none;
  background-color: transparent;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.recent-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 20px;
}

@media (min-width: 1024px) {
  .comment-answer .send-btn {
    padding: 0 46px;
  }
  .comment-answer input {
    margin-right: 30px;
    padding: 17px 10px;
    outline: none;
    position: relative;
  }
}
.recent-writer-box {
  padding: 8px;
  border-bottom: 0.5px solid #a0a6a9;
}
.recent-writer-box .recent-writer-title {
  padding-left: 10px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #273240;
  margin: 0;
}

.profile-img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  object-fit: cover;
}

/* Sell Page Css Start */
.sell-container {
  margin-left: 166px;
}
.sell-box {
  width: 100%;
  margin-top: 74px;
  overflow-y: scroll;
}
.sell-box-container {
  margin: 30px;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.nav {
  border-bottom: 1px solid #f6f6f6 !important;
}
.nav-item {
  /* margin: 0 20px;
  padding: 20px 0 0 0; */
  display: flex !important;
  flex-direction: row !important;
  /* margin-bottom: 10px; */


}
.nav-item button{
  border-radius: 5px;
}
.nav-link.active{
 
}

.step-btn {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  transition: all 0.3s;
  font-size: 14px !important;
  line-height: 23px !important;
  letter-spacing: 0.5px !important;
  color: #000000;
  border: none;
  background-color: transparent;
}
.step-btn:disabled {
  color: #efc348;
  background-color: transparent;
}
.nav-link:hover {
  color: #efc348 !important;
}

.step-one-container {
  margin: 54px 39px 47px 57px;
}
.step-one-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.file-plush-btn {
  background-color: transparent;
  border: 1px solid #e8e8e8;
  margin-bottom: 23px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100px;
  width: 100px;
}
.file-plush-btn img{
  width: 50%;
}
.file-plush-btn::before {
  position: absolute;
  background-color: #d9d9d9;
  height: 6px;
  width: 60px;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.file-plush-btn::after {
  position: absolute;
  background-color: #d9d9d9;
  height: 60px;
  width: 6px;
  transform: rotate(30deg);
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.step-one-title span {
  font-family: "Poppins";
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.step-one-input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 30px;
}
.bottom-btn {
  margin-right: 39px;
  background: #ffffff;
}
.bottom-btn .prev-btn,
.bottom-btn .next-btn {
  font-family: "Poppins";
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  padding: 10px 16px;
}
.bottom-btn .prev-btn {
  border-radius: 10px 0px 0px 10px;
}
.bottom-btn .next-btn {
  border-radius: 0px 10px 10px 0px;
}

.step-two-container {
  margin: 54px 39px 47px 57px;
}
.file-upload-btn {
  border: 1px dashed #d9d7d7;
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 181px;
  margin-bottom: 30px;
}

.step-three-container {
  margin: 54px 39px 47px 57px;
}
.bottom-btn .submit-btn {
  margin-left: 20px;
  font-family: "Poppins";
  background-color: rgba(255, 207, 22, 0.4);
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  padding: 10px 16px;
}

.all-card {
  padding: 20px 22px;
  background-color: #ffffff;
  margin-bottom: 30px;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.all-card-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.country-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #908f96;
}
.all-card-text {
  padding-left: 10px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.follow-profile {
  padding: 20px 24px 10px 24px;
  background-color: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
}
.follow-btn {
  background-color: #EFC348;
  border-radius: 5px;
  font-family: "Poppins";
  border: none;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.follow-content {
  border-top: 0.5px solid #dbe5eb;
  padding: 6px 0;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.follow-content:last-child {
  padding-bottom: 0;
}
.top-member-card {
  padding: 12px 24px;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.top-member-card .div {
  border-bottom: 0.5px solid #a0a6a9;
  padding-bottom: 4px;
}
.top-member-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #273240;
  padding-left: 10px;
}
.review-card {
  margin-bottom: 4px;
}
.review-card-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #273240;
  /* padding-bottom: 4px; */
}
.review-rating-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #7f8389;
  padding-bottom: 4px;
}

/* Book Review Page CSS */

.book-review-box {
  background: #f6f6fb;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid grey;
}
.book-review-box img{
  height: 180px;
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 1380px) {
  .book-review-box img{
    height: 150px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .book-review-box img{
    height: auto;
    width: 100%;
   

  }
}
.book-review-title {
  font-family: "Poppins";
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  padding-top: 15px;
}
.book-review-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8c8c8c;
}
.review-total {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #8c8c8c;
  padding: 10px 0;
  /* border-bottom: 0.5px solid #a0a6a9; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.recent-box {
  background-color: #ffffff;
  padding: 18px 10px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  cursor: pointer;
}
.recent-box .review-card {
  border-bottom: 0.5px solid #a0a6a9;
  padding-bottom: 10px;
}
.book-review-box a {
  text-decoration: none;
  color: #000000;
}
.book-review-box a:hover {
  text-decoration: none;
  color: #000000;
}
.review-customer-box {
  padding: 0 24px;
}
.book-customer-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.book-customer-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.rating-total {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8c8c8c;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.customer-review-purchase {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.customer-review-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.right-customer-box {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
.right-customer-card {
  padding-bottom: 20px;
  border-bottom: 0.5px solid #dbe5eb;
  margin-top: 20px;
}
.right-customer-card:first-child {
  margin-top: 0px;
}
.right-customer-content {
  padding-left: 10px;
}
.right-customer-content h6 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.right-customer-content h5 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
}
.preview-img img {
  background-position: center;
  background-size: cover;
  object-fit: cover;
}
.product-title {
  padding-top: 33px;
  padding-bottom: 12px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #000000;
}
.product-detail-box {
  background: #ffffff;
  border: 1px solid #b3b8ba;
  border-radius: 10px;
  padding: 20px;
}
.product-detail-text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.product-detail-text span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.read-more-btn {
  border: none;
  margin-left: 10px;
  padding: 10px 14px;
  background-color: transparent;
  color: #f9dd6e;
}

/* Quotes Page Css */

.quotes-card {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 22px 32px;
  margin-bottom: 30px;
}
.line {
  position: relative;
  height: 2px;
  width: 100%;
  background-color: #dbe5eb;
  opacity: 0.8;
  margin-top: 50px;
}
.quotes-card ul li a {
  text-decoration: none;
  color: rgba(0, 0, 128, 1);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

/* Profile Page Css */

.profile-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  /* margin-bottom: 27px; */
}
.profile-btn {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.profile-name {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #000000;
  padding-left: 15px;
}
.profile-name i {
  font-size: 20px;
  padding: 6px;
  border-radius: 6px;
  color: #ffffff;
  margin-left: 15px;
  background-color: #000000;
}
.profile-see-btn {
  background-color: transparent;
  color: #000000;
  border: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #000000;
}

.profile-blog-box {
  background: #fefefe;
  border: 1px solid #b3b8ba;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 22px 32px;
}
.profile-blog-title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.profile-blog-country {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #908f96;
}
.profile-blog-text {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-bottom: 2px solid #dbe5eb;
  padding-bottom: 20px;
}
.blog-gmail {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.blog-date {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  border-left: 2px solid #d9d9d9;
}

.blog-detail-card .blog-detail-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.blog-detail-card .blog-detail-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 30px;
}
.blog-detail-card .blog-detail-description {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.blog-detail-card {
  margin-bottom: 18px;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 17px 32px;
}
.blog-detail-comment {
  margin-bottom: 18px;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 17px 32px;
  
  overflow-y: scroll;
}
.blog-comment-box {
  position: sticky;
  bottom: 0;
}
.path-box {
  background: none;
  margin-bottom: 5px;
  border-radius: 6px;
  /* padding: 10px 14px; */
 margin-top: 5px;
  /* padding-left: 10px;
  padding-right: 20px !important; */
}

.path-box2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-button {
  color: transparent !important;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  outline: none !important;
}
.accordion-button:focus {
  border: none !important;
}
.comment-btn {
  border: none !important;
  background-color: transparent !important;
}
.comment-box {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  padding-top: 10px;
}
.comment-header {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 0;
}
.comment-box-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding-top: 6px;
}
.add-comment {
  padding: 10px 16px 0 16px;
  background-color: #f2f2f2;
  position: sticky;
  bottom: 0;
}
.add-comment .comment-input {
  position: relative;
  width: 100%;
  padding: 6px 14px;
  outline: none;
}
.add-comment .comment-btn i {
  position: absolute;
  bottom: 30px;
  right: 20px;
  font-size: 26px;
}

.left-side-add-icon i {
  font-size: 20px;
  padding: 20px 23px;
  background-color: #f9dd6e;
  border-radius: 50%;
  text-align: center;
  color: #656052;
}

@media (min-width: 1024px) {
  .left-side-add-icon {
    font-size: 50px;
  }
}

.comment-boxes {
  padding: 10px;
}
.comment-boxes .comment-profile {
  height: 42px;
  width: 42px;
  object-fit: cover;
}
.comment-boxes input {
  padding: 19px 10px;
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}
.comment-boxes .blog-comment-btn {
  padding: 6px 14px;
  background-color: #2ca9d9;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}
.comments-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}
.comments-container .comment-profile {
  height: 42px;
  width: 42px;
  object-fit: cover;
}
.comments-container .comment-text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-profile-btn {
  border: none;
  background-color: #000;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 6px;
}
.author-text {
  position: relative;
  bottom: -40px;
  text-align: right;
  font-weight: 500;
  font-size: 16px;
}

.all-media {
  /* background-color: #fff; */
  border-radius: 4px;
}
.tab-content {
  padding: 10px 14px;
}
.nav-item {
  padding: 0;
}
.nav-link:hover {
  color: #000;
}
.nav-tabs .nav-link {
  border: none;
  color: #000;
  font-weight: 600;
  border: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
}

.collage-card {
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 32px;
  margin-bottom: 12px;
}
.collage-card .collage-card-title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.collage-card .follow-btn {
  color: #000080;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  background-color: transparent;
}
.collage-card .collage-hour {
  color: #545454;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 6px;
}
.collage-card .collage-content-title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 16px;
  padding-bottom: 9px;
}
.collage-card .collage-content-text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.collage-card .collage-card-border {
  opacity: 0.8;
  background-color: #dbe5eb;
  height: 2px;
  width: 100%;
  margin-top: 9px;
  margin-bottom: 18px;
}
.collage-card .like-btn {
  border: none;
  background-color: transparent;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.collage-card .comment-btn {
  border: none;
  background-color: transparent;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.preview-img {
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 50px;
  width: 50px;
  /* margin-left: 30px; */
}
.add-btn {
  padding: 8px 22px;
  border: none;
  background-color: #800000;
  border-radius: 10px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content-added {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px 20px;
}

.add-question {
  background-color: #f9dd6e;
  color: #000;
  font-weight: 300;
  font-size: 15px;
  border: none;
  border-radius: 8px;
  padding: 1px 20px !important;
  width: 200px;
  margin-top: 10px;

}
.disabled-btn {
  background-color: #f9dd6e87;
  color: #000000ba;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  padding: 0 20px;
}

.submit-content-btn {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  padding: 10px 26px;
  border-radius: 6px;
  background-color: #ffcf16;
  color: #fff !important;
  font-weight: 500;
  border: 2px solid #ffcf16;
  transition: all 0.6s;
  margin-right: 16px;
}
.submit-content-btn:hover {
  color: #ffcf16 !important;
  background-color: #fff;
  border: 2px solid #ffcf16;
}
.modal.show .modal-dialog {
  max-width: 500px;
}
.buy-container {
  background-color: #fff;
  border-radius: 6px;
  padding: 14px;
}
.buy-card {
  border-radius: 10px;
  border: 1px solid #b3b8ba;
  padding: 15px 6px;
  margin-bottom: 16px;
}
.buy-card .buy-card-title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.5px;
  margin-left: 10px;
  margin-bottom: 4px;
}
.buy-card .buy-card-text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.5px;
  margin-left: 10px;
  margin-bottom: 4px;
}
.buy-card .product-price {
  color: #d49a0f;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.5px;
  padding-left: 10px;
  padding-top: 13px;
  padding-bottom: 5px;
}
.buy-card .product-discount-price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
  margin-left: 10px !important;
}
.add-to-cart-btn {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  background-color: rgba(255, 207, 22, 0.4);
  padding: 10px 32px;
  border-radius: 6px;
  border: none;
  margin-right: 10px;
}
.buy-now-btn {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  background-color: rgba(255, 207, 22, 0.4);
  padding: 10px 32px;
  border-radius: 6px;
  border: none;
  margin-right: 10px;
}
.product-detail-box {
  border-radius: 10px;
  border: 1px solid #b3b8ba;
  background: #fff;
  padding: 20px;
}
.product-detail-box .product-box-title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.product-detail-box .product-box-text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px !important;
}

/* Cart CSS Start */

.quantity-btn {
  border: none;
  background-color: #f9dd6e;
  padding: 0px 12px;
  font-size: 20px;
  font-weight: 700;
  margin: 0 10px;
}
.cart-section {
  overflow: hidden;
  background-color: #f1f2f2;
}
.newcart {
  height: 100%;
  max-width: 1433px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 30px 0;
  border-radius: 6px;
  padding: 50px;
}
.cart-left {
  height: 100%;
  width: 60%;
  padding-left: 20px;
  padding-right: 20px;
}
.cart-left button , .cart-right button{
  background-color: #EFC348;
  border-radius: 10px;
  color: white;
  border: none !important;
}
.cart-right {
  height: 100%;
  width: 40%;
  /* padding: 100px; */
}
.addr {
  /* width: 761px; */
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.addr h1 {
  font-family: "Cinzel", serif;
  font-size: 18px;
}
.addr p {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 14px;
}
.addr button {
  font-family: "Playfair Display", serif;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  width: 136px;
  border: 1px orangered solid;
}
.cart-heading {
  font-size: 36px;
  font-family: "Cinzel", serif;
}
.offer {
  padding-top: 20px;
  display: flex;
}
.offer h1 {
  font-size: 18px;
  font-family: "Cinzel", serif;
}
.offer p {
  font-family: "Playfair Display", serif;
  font-size: 14px;
  margin-top: 5px;
}
.cart-table {
  width: 100%;
}
.table-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table-heading p {
  font-size: 16px;
  font-family: "Cinzel", serif;
}
.NewcartCard {
  width: 100%;
  height: 134px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 15px;
}
.cart-img {
  height: 134px;
  width: 109px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.cart-img img {
  height: 134px;
  width: 109px;
}
.cart-product-select {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 20px;
  margin-left: -40px;
}
.selected-qty {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.total-amt {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 130px;
}
.cart-product-select h5 {
  font-size: 20px;
  font-weight: 400;
  font-family: "Cinzel", serif;
}
.cart-product-select p {
  font-family: "Playfair Display", serif;
  font-size: 16px;
}
.applycoupon {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.applycoupon h1 {
  font-size: 18px;
  font-weight: 400;
  font-family: "Cinzel", serif;
}
.coupon {
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.coupon button {
  width: 117px;
  height: 35px;
  border: 1px orangered solid;
}
.coupon p {
  font-family: "Playfair Display", serif;
  font-size: 14px;
}
.totalMRP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Playfair Display", serif;
  font-size: 14px;
  margin-bottom: 20px;
}
.itemselected {
  font-family: "Playfair Display", serif;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.discount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Playfair Display", serif;
  font-size: 14px;
  margin-bottom: 20px;
}
.billline {
  height: 2px;
  background-color: black;
}
.place-order-btn {
  height: 56px;
  width: 100%;
  background-color: #292929;
  color: white;
}

/* Cart CSS End */

/* Contact Us CSS Start */

.contact-section {
  background-color: #fee38c;
  padding-top: 74px;
}
.contact-section .contact-title {
  text-align: center;
  padding: 60px 0;
  color: #5c5014;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
}

.contact-box {
  max-width: 800px;
  margin: auto;
  background-color: #886201;
  padding: 30px;
  border: 1px solid #be9f44;
  display: flex;
  gap: 66px;
}
.address-box {
  background-color: #fff;
  padding: 18px;
  width: 30%;
}
.address-box .address-title {
  text-transform: uppercase;
  color: #787a65;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  font-family: "Playfair Display", serif;
}
.address-box .contact-text {
  font-size: 14px;
  color: #9e8d6f;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 18px;
  font-family: "Playfair Display", serif;
}
.form-box {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form-box .form-input {
  padding: 14px 10px;
  width: 100%;
  color: #7f7b61;
  font-weight: 400;
  font-size: 14px;
  font-family: "Playfair Display", serif;
}
.form-box .send-btn {
  border: none;
  background-color: #fce28b;
  text-transform: uppercase;
  color: #7f671d;
  padding: 6px 10px;
  width: 40%;
  font-family: "Playfair Display", serif;
}

/* Contact Us CSS End */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000075;
  z-index: 1000;
}

.loader-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  /* border-top: 4px solid #ffffff; */
  /* border-right: 4px solid transparent; */
  /* box-sizing: border-box; */
  /* animation: rotation .7s linear infinite; */
}
.loader-container .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: -25px;
  top: -30px;
  width: 82px;
  height: 95px;
  border-radius: 50%;
  /* border-bottom: 4px solid #f9dd6e; */
  /* border-left: 4px solid transparent; */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid #EFC348;
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.remove-btn {
  border: none;
  background-color: rgb(241, 236, 236);
  color: #ff0000;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: normal;
  margin-top: -150px;
}
.detail-page-review{
  display: flex;
  align-items: center;
}

.blogtitle{
  text-align: start !important;
}
.search-container{
  height: 40px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .path-box2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
    align-items: start;
  }
  .search-container{
    width: 100%;
  }
  .search-container input , .add-question {
    width: 100% !important;
  }
  .search-container input{
    border: 1px solid black;
    height: 40px;
    padding-left: 15px;

  }
  .add-question{
    height: 40px;
  }
  .allimg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .all-card{
    padding: 29px 20px;
  }
  .center-box{
    padding: 110px 10px 110px 10px;
  }
  .all-card-text{
    font-size: 10px;
  }

}
.pd-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 850px) {
  .newcart{
    display: flex;
    flex-direction: column;
  }
  .cart-left ,.cart-right{
    width: 100%;
  }
  
}
@media (max-width:600px) {
  .addr{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
  }
  .cart-left button{
   
  }
  .newcart{
    padding: 20px 0;
    border-radius: 6px;
    padding: 0px;
  }
  .cart-right{
    padding: 20px;
  }
}
.addcontint-inps{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding-left: 100px;
  padding-right: 100px; */


  
}
.bg-cards{
 display: flex;
 gap: 20px;
 width: auto;
 width: 300px;

}
.newblogcards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.qt-clo{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qt-clo p{
  margin-bottom: 0;
}
.bg-card{
  width: 100%;
  border-radius: 15px;
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.bg-card-img{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.bg-card-img-heading{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.followbtn{
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background-color: #EFC348; /* Highlight color */
  border: none;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}
.likes-comments{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.blog-dates{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Active tab styles */
.nav-link.active {
  color: #fff !important;
  background-color: #EFC348 !important; 
  border-color: #EFC348 !important;
  cursor: default;
}



.PostAnswer{
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #EFC348;
  border: 1px solid #EFC348;
  color: white;
  font-size: 20px;
}


.newaddcontact{
  /* padding: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  /* background-image: url(./assets/Screenshot\ 2024-09-17\ 164524.png); */
}
.newaddcontact-div{
  background-color: #EFC348;

  /* padding: 50px; */
  width: 100%;

}
.newaddcontact-heading{
  background-color: #EFC348;
  padding: 20px;
  /* border-radius: 0px 0px 0px 100px; */

}
.newaddcontact-heading-div{
  background-color: #f6f6f6;

}
.newaddcontact-heading{
  display: flex;
  justify-content: center;
  align-items: center;

}
.newaddcontact-heading h2{
  color: white;
  font-weight: 700;

}
.newaddcontact-ips{
  background-color: #f6f6f6;

  padding: 50px;
  /* border-radius: 0px 100px 0px 0px; */
  
  
}
.new-flex{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.newaddcontact-ips select , .newaddcontact-ips input{
  /* width: 30%; */
  height: 40px;
  padding-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;

}
.cname{
  /* width: 20%; */
}
.TextEditor{
  width: 100%;
  padding: 20px;
}
.newaddcontact-ips textarea{
  
}
.text-center2{
  display: flex;
  flex-direction: row;
}
.text-center2 button{
  width:50%;

}

.text-center button:hover{
  color: #EFC348 !important;
}
@media (max-width: 1024px) {
.newaddcontact-div{
  width: 100%;
}
.new-flex{
  gap: 10px;
}
}
@media (max-width: 768px){
  .newaddcontact-div{
    width: 100%;
  }
  .newaddcontact{
    padding: 0px;
    background: none;
  }
  .newaddcontact-heading{
    background-color: #EFC348;
    padding: 30px;
  }
.newaddcontact-ips{
  padding: 30px;
}
}
@media (max-width: 600px){
  .newaddcontact-ips{
    background-color: #f6f6f6;
 
    border-radius: 0px 80px 0px 0px;
    
  }
  .newaddcontact-heading{
    background-color: #EFC348;
   
    border-radius: 0px 0px 0px 80px;
  }
}
@media (max-width: 600px){
  .new-flex{
    flex-direction: column;
  }
  .cname{
    width: 100%;
  }
}
.member-four-options{
  display: flex;
  flex-direction: row;
  gap: 15px;
 
}
.opts-1{
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  gap: 15px;
}
@media (max-width: 1250px){
.member-four-options{
  flex-direction: column;

}

}
@media (max-width: 768px){
  .member-four-options{
    flex-direction: row;
  
  }
}
@media (max-width: 420px){
  .member-four-options{
    flex-direction: column;
  
  }

}
.search-container input{
  border: none;
  background: none;
  height: 100%;
  width: 100%;
 
}
.search-container input:focus {
  outline: none;
}
.search-container{
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #fdeec2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;


}
.youranswer{
  padding: 20px;
  border-radius: 15px;
  width: 100%;
}
.n-e-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 850px){

  .n-e-w{
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: start !important;
    }
    }
    @media (max-width: 850px) {
      .n-e-w {
        align-items: flex-start !important;
      }
    }

    .flag-name{
      display: flex;
      flex-direction: column;
      /* gap: 10px; */
    }








    .navbar { box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); max-width: 100%; margin: auto;  position: sticky; top: -1px; z-index: 1000; background-color: #FFF;}

.top21 {
  padding: 0px 30px;
}

.topbar1 .free-text {
  font-size: 16px;
}

.topbar1 p {
  font-size: 16px;
}

@media(max-width: 768px) {
  .topbar1 .free-text { font-size: 12px;}
  .topbar1 p {
    font-size: 12px;}
}

.top22 {
  border-left: 1px solid rgba(255, 255, 255, 0.521);
}

.nav12 {
  padding: 0px 20px;
}

.nav-text a {
  margin: 0px 20px;
}

.nav12 img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0px 15px;
}

.nav12 svg {
  margin: 0px 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.nav-logo{
  width: 91px;
}
.nav-logo img{
  width: 100%;
}

.nav-logo {
  margin-right: 20px;
}
.home-main { overflow: hidden;}
.home1 {
  background: #fff;
}

.home11 {
  padding: 2rem;
}

.home111 {
  margin-bottom: 1.5rem;
}

.home111 h1 {
  font-size: 50px;
}

.home2 {
  margin: 4rem 0rem;
}

.home21 {
  padding: 0px 6rem;
}

.home211 {
  margin-right: 5rem;
  width: 45%;
}

.home211 img {
  width: 80%;
}


@media(max-width: 678px) {
  .home211 img { display: none;}
} 
.home212 {
  width: 55%;
}

.text {
  position: relative;
}

.text img {
  position: absolute;
  bottom: 0px;
  z-index: -1;
  left: 0px;
}

.text h3 {
  font-size: 39px;
}

.home2121 p {
  font-family: 'Forum', cursive;
  margin-bottom: 10px;
}

.home2121 .text {
  margin-bottom: 15px;
}

.home2-card {
  margin-right: 10px;
  position: relative;
  width: 50%;
}

.home2-card1 {
  background: #FFCF16;
  background-repeat: no-repeat; background-size: cover; background-position: center; object-fit: cover;
  border-radius: 10px;
  top: -14px;
  left: 20px;
  /* background-image: url('../public/images/quote.png'); */
  height: 30px; width: 30px;
}

.home2-card2 p {
  font-family: 'Forum', cursive;
  font-size: 20px;
  text-align: center;
}

.home2-card2 {
  z-index: 10;
  height: 100%;
  border: 3px solid #FFF4C8;
  padding: 8px;
  border-radius: 10px;
}

.home2-card2 h4 {
  margin-bottom: 10px;
  text-align: center;
}

.home31 {
  padding: 0rem 4rem;
}

.home311 {
  margin-bottom: 5rem;
}

.home3121 {
  cursor: pointer;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home3121 img {
  margin-bottom: 30px;
}

.text img {
  margin-bottom: 0 !important;
}

.home3 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.footer {
  /* background: linear-gradient(0.25turn, #9f1c24, #7a182e, #491231); */
  background-color: #911a27;
}

.footer1 {
  padding: 4rem 6rem;
  /* align-items: center; */
}

.footer p,
.footer h5 {
  color: white;
  font-family: 'Forum', cursive;
}

.foot0 h5 {
  margin-bottom: 20px;
}

.foot0 p {
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
}

.footer-card { max-width: 100%; }
.footer-title { text-align: justify; font-size: 70px !important; line-height: 70px; text-transform: uppercase; font-family: 'Poppins', sans-serif !important; font-weight: 700;}
.footer-text { text-align: justify; font-size: 28px !important; text-transform: uppercase; font-weight: 600; font-family: 'Poppins', sans-serif !important;}
.foot3 h5 {
  margin-bottom: 0px;
}
.footer-link-title { font-size: 30px; font-weight: 600; letter-spacing: 1px;}


@media(max-width: 768px) {
  .footer-title { font-size: 40px !important; line-height: 40px;}
  .footer-text { font-size: 16px !important; }
}


.topbar { background: #911a27; }

.foot1 {
  width: 30%;
  text-align: center;
  padding-left: 80px;
}

.foot2 {
  width: 30%;
}

.foot3 {
  /* width: 40%; */
  margin-top: 20px;
}

.nav12 {
  display: flex;
}

.mob-nav1 {
  position: fixed;
  flex-direction: column;
  align-items: center;
  height: 100%;
  top: 48px;
  right: -1000px;
  transition: right 0.17s linear;
  width: 100%;
  background: white;
  z-index: 99;
  padding-top: 3rem;
}

.nav13 {
  width: 28rem;
}

.glass {
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
   /* top: 42px; */
}
.cus-modal{
  background: rgba(0, 0, 0, 0.185);
}
#registerModal{
  z-index: 999999;
}
#registerModal label{
  font-size: 18px;
}
#registerModal input{
  font-size: 18px;
}
#registerModal button{
  font-size: 18px;
}

.mob-nav1 a {
  margin-bottom: 20px;
  padding: 5px;
}

.mob-active {
  right: 0;
}

.mob-nav {
  z-index: 9999;
}

.home2-card2 h4 {
  font-size: 24px;
}
.admin-main div , .admin-main h4 , .admin-main p , .admin-main span , .admin-main select , .admin-main option{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.sc-kgKVFY{
  font-size: 30px;
}
.admin-main{
  min-height: 70vh;
}
.Toastify{
  z-index: 9999999999;
  position: relative;
}

@media screen and (max-width: 1170px) {
  .nav1{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  a{
    font-size: 19px;
  }
  .nav-text a {
    margin: 0px 10px;
  }
}
.logo img{
  height: 65px;
}
@media screen and (max-width: 900px) {
  .nav-logo img { height: 50px; object-fit: cover; width: 50px;}
  .nav12 {
    display: none;
  }

  .nav-text {
    display: none;
  }

  .mob-nav {
    display: block;
  }

  .mob-nav1 {
    display: flex;
  }

  .nav11 {
    width: 100%;
    justify-content: space-between;
  }

  .nav1 {
    padding: 6px 1rem;
  }

  .home21 {
    padding: 0px 3rem;
  }

  .home21 {
    flex-direction: column;
  }

  .home211 {
    margin-right: 0;
    width: 100%;
    margin-bottom: 3rem;
  }

  .home212 {
    width: 100%;
    text-align: center;
  }

  .text {
    width: fit-content;
    margin: auto;
  }

  .home2122 {
    flex-direction: column;
  }

  .home2-card {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .home312 {
    flex-direction: column;
    align-items: center;
  }

  .home3121 {
    width: 100%;
    margin-bottom: 4rem;
  }

  .home311 {
    margin-bottom: 3rem;
  }

  .home3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .footer1 {
    padding: 2rem;
  }
  .nav13{
    display: none;
  }
  .glass{
    top: 150px;
  }
}

.home2122 { gap: 30px; margin-top: 30px;}

@media screen and (max-width: 650px) {
  .home2122 { gap: 24px; margin-top: 48px;}
  .home211 { max-width: 60%; margin: auto; display: none; margin-bottom: 30px;}
  .topbar1 {
    padding: 0.5rem;
  }

  .home111 h1 {
    font-size: 50px;
  }

  .home11 {
    padding: 1.5rem;
  }

  .footer1 {
    flex-direction: column;
    /* align-items: center; */
  }

  .foot0 h5 {
    margin-bottom: 6px;
  }

  .foot0 p {
    margin-bottom: 9px;
  }

  .foot1 {
    padding-left: 0;
    width: 100%;
  }

  .foot2 {
    width: 100%;
  }

  .foot3 {
    width: 100%;
  }

  .foot0 {
    text-align: center;
    margin-bottom: 20px;
    display: flex; justify-content: center; align-items: center; flex-direction: column;
  }

  .top21 {
    padding: 0px 9px;
  }
}

@media screen and (max-width: 400px) {
  .topbar1 p {
    font-size: 9px;
  }

  .top21 {
    padding: 0px 5px;
  }

  .home111 h1 {
    font-size: 35px;
  }

  .home21 {
    padding: 0px 1rem;
  }

  p {
    font-size: 16px;
  }

  a {
    font-size: 16px;
  }

  .text h3 {
    font-size: 30px;
  }

  .home2-card2 h4 {
    font-size: 20px;
  }

  #registerModal label{
    font-size: 13px;
  }
  #registerModal input{
    font-size: 13px;
  }
  #registerModal button{
    font-size: 13px;
  }
}


.footer-link-icon { font-size: 80px;}


@media(max-width: 768px) {
  .footer-link-icon { font-size: 40px;}
}
.home-top-nav{
  height: 50px;
  background-color: hsl(353, 69%, 33%);
  color: white;

}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-aboutus{
  display: flex;
  flex-direction: row;
  padding: 50px;
}
.home-aboutus-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-aboutus-left img{
  width: 80%;
}
.home-aboutus-right{
  width: 50%;
}
.home-aboutus-right h2{
  font-family: "Fondamento", cursive;
  font-size: 39px;
}
.home-aboutus-right p{

  font-family: "Forum", serif;
  font-size: 22px;
}
.mission-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home-mission{
  text-align: center;
  width: 45%;
  padding: 20px;
  border: 1px solid #EFC348;
  border-radius: 15px;

}
.home-mission h4{
  font-family: "Fondamento", cursive;
  font-size: 24px;
}
.home-missiont p{

  font-family: "Forum", serif;
  font-size: 20px;
}
.ourproduct{
  padding: 50px;
}
.ourproduct h1{
  font-size: 39px;
  font-family: "Fondamento", cursive;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;

}
.ourproduct-newcard{
  width: 20%;
  height: 350px;
 text-align: center;
 display: flex;
 flex-direction: column;
 /* flex-wrap: wrap; */
 /* gap: 20px; */
 text-align: center;
 justify-content: center;
 align-items: center;

}
.ourproduct-newcard img{
  width: 100%;
  /* height: 300px; */


}
.ourproduct-newcard h3{
  font-size: 22px;
  font-family: "Fondamento", cursive;
  text-align: center;
}
.ourproduct-newcards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.New-footer{
  display: flex;
  flex-direction: row;
  padding: 50px;
  background-color: #911A27;
  color: white;
  justify-content: space-between;
}
.New-footer ul{
  width: 30%;


  font-size: 22px;
  font-family: "Fondamento", cursive;
}
.New-footer img{
  margin-bottom: 20px;
  height: 100px;

}
.footlogo{
  
}
.New-footer ul li{
  list-style: none;
  
  font-family: "Forum", serif;
  font-size: 15px;
  margin-top: 10px;
}

.ALLRIGHTSRESERVED{
  height: 50px;
  background-color: hsl(353, 69%, 33%);
text-align: center;
}
.ALLRIGHTSRESERVED p{
  font-size: 18px;
  font-family: "Fondamento", cursive;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.post-q-answer{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.post-q-answer button{
  background-color: #EFC348;
  color: white;
  border-radius: 10px;
  border: 1px solid #EFC348;
  height: 40px;
  font-size: 18px;
  font-weight: 500;

}
.post-q-answer textarea{
  padding: 20px;
  border-radius: 15px;
}
.new-update-searchbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.Writeareview{
padding-left: 15px;
padding-right: 15px;
  height: 40px;
  border: none;
  background-color:#EFC348;
  color: white;
  border-radius: 5px;


}
.contentType-ips{
  width: 150px;
}
.heading-ips{
  width: 1100px;
}
.link-media{
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.link-media li i{
  font-size: 20px;

}
.none{
text-decoration: none;
color: white;
}
.content-card{
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.content-card h4{
  font-size: 20px;
}
.content-card p{
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 400;
}
.book-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.close-modal-button{
  background-color: #EFC348 !important;
}
.memberfollow{
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #EFC348;
  color: white;
  border: none;
}
.filter-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gender-image-container{

}
.gender-image-container img{
  height: 50px;
  width: 50px;
}
.new-nav-link{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.new-nav-link h6{
  padding-bottom: 0;
}
/* Hide the dropdown indicator (down arrow) */
.dropdown-toggle::after {
  display: none !important;
}
.path{
  width: 100%;
  background-color: white;
}
.form-select{
border-radius: 30px !important;
font-size: 12px !important;
background-color: #F4F4F5 !important;
border: none !important;

}
.form-select:hover{
  background-color: rgb(222, 222, 222) !important;

}
.categories-container{
  display: flex;
  flex-direction: row;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  gap: 20px;
}
.nav-get p{
  font-size: 12px !important;
  font-weight: 400 !important;
}
.nav-get p a{
  font-size: 12px !important;
  font-weight: 400 !important;
}
.ct-boxes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 905px) {
  .new-nav-link{
    display: none;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-top: 10px;
  }
}
@media(max-width: 1090px) {
  .home-aboutus{
    flex-direction: column-reverse;
    gap: 50px;
  }
  .home-aboutus-left{
    width: 100%;
  }
  .home-aboutus-right{
    width: 100%;
  }
}
@media(max-width: 650px) {
  .mission-div{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .home-mission{
    width: 100%;
  }
  .home-aboutus{
    padding: 20px;
  }
}
@media(max-width: 768px) {
  .ourproduct-newcards{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ourproduct-newcard{
    width: 80%;
  height: auto;

  }
  .New-footer{
    padding: 20px;
    flex-wrap: wrap;
  }
  .New-footer ul{
    width: 40%;
    padding-left: 0;
  }
  .ALLRIGHTSRESERVED{
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.center-box {
  height: 100%;
  width: 100%;
  padding: 70px 10px 110px 10px;
  overflow-x: hidden;
}
}

@media(max-width: 600px) {
  .New-footer ul{
    width: 100%;
  }

}
.mypage-headging{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.forgot-pass{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.forgot-pass p{
  margin-bottom: 0;
  color: black !important;
  cursor: pointer;
}
.book-qus{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  position: relative;
  padding: 15px 20px;
  cursor: pointer;
  height: 100%;
}

.nav-item:hover .mega-dropdown {
  display: flex;
}

.mega-dropdown {
  position: absolute;
  top: 70px;
  left: 0;
  width: 600px;
  background: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  display: none;
  padding: 20px;
  gap: 20px;
  border-radius: 5px;
}

.dropdown-column {
  flex: 1;
}

.dropdown-column h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.dropdown-column ul {
  list-style: none;
}

.dropdown-column ul li {
  padding: 5px 0;
}

.dropdown-column ul li a {
  text-decoration: none;
  color: #666;
  font-size: 14px;
  transition: 0.3s;
}

.dropdown-column ul li a:hover {
  color: #000;
}
.btn:hover{
  color: #000 !important;
}
/* Change arrow color */
.swiper-button-next, .swiper-button-prev {
  color: #ff0000 !important; /* Change to your desired color */
}

/* Change dot color */
.swiper-pagination-bullet {
  background: #ff0000 !important; /* Change to your desired color */
}

/* Change active dot color */
.swiper-pagination-bullet-active {
  background: #ff0000 !important; /* Change to your desired color */
}