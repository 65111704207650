.login {
  margin-top: 100px;
  margin-bottom: 50px;
}
.login-container {
  padding: 40px;
  border: 2px solid #f6f6fb;
  border-radius: 10px;
  box-shadow: 10px 14px 60px -38px rgba(0,0,0,0.75);
}
.login-container .login-title {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 700;
  color: #000000;
  padding-bottom: 20px;
}
.login-input-container {
  margin-top: 30px;
}
.login-input-container .input-title {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  padding-bottom: 10px;
}
.login-input-container .input-box {
  font-size: 16px;
  background-color: #f4f3f3;
  padding: 10px 12px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  outline: none;
  border-radius: 4px;
  margin-bottom: 20px;
}
.login .submit-btn {
  background-color: #f6f6fb;
  color: #000;
  padding: 8px 30px;
  border-radius: 10px;
  border: 2px solid #000;
  transition: ease 0.5s;
  margin: 20px 0;
  font-family: "Libre Baskerville";
  font-size: 14px;
  font-weight: 600;
}
.login .submit-btn:hover {
  border: 2px solid #000;
  background-color: #000;
  color: #f6f6fb;
}
.login p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.login p a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 480px) {
  .login-img img {
    height: 350px;
    width: 350px;
  }
  .login-container {
    padding: 20px;
  }
  .login-container .login-title {
    font-size: 24px;
    padding-bottom: 0px;
  }
  .login-input-container .input-box {
    font-size: 16px;
    padding: 8px 6px;
    margin-bottom: 10px;
  }
  .login p {
    font-size: 22px;
  }
}

/* Profile Page Css */

/* 
.profile-hero-section { background-color: #ffffff; margin-top: 80px; padding: 50px 0;}
.profile-title { color: #fe9c3a; font-size: 40px; font-family: 'Poppins', sans-serif; font-weight: 600;}
.profile-circle { position: relative; height: 120px; width: 120px; background-color: #fe9c3a; border-radius: 50%; border: 4px solid #020289; margin: 20px 0;}
.profile-circle h2 { position: absolute; color: #020289; font-size: 50px; text-transform: uppercase; font-weight: 700; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.profile-name { font-weight: 500; font-size: 16px; font-family: 'Poppins',sans-serif; text-transform: capitalize; padding-bottom: 5px;}
.profile-email span { font-weight: 500; font-size: 16px; font-family: 'Poppins',sans-serif;}
.profile-email { font-weight: 400; font-size: 16px; font-family: 'Poppins',sans-serif;}
.profile-btn .profile-login { font-size: 16px; font-weight: 500; font-family: 'Poppins', sans-serif;  margin-top: 20px; padding: 6px 24px; border: 2px solid #fe9c3a; color: #FFFFFF; background-color: #fe9c3a; border-radius: 6px; transition: all 0.5s;}
.profile-btn .profile-logout { font-size: 16px; font-weight: 500; font-family: 'Poppins', sans-serif;  margin-top: 20px; padding: 6px 24px; border: 2px solid #fe9c3a; color: #FFFFFF; background-color: #fe9c3a; border-radius: 6px; transition: all 0.5s;}
.profile-btn .profile-login:hover, .profile-btn .profile-logout:hover { color: #fe9c3a; border: 2px solid #fe9c3a; background-color: transparent;}

.order-details .dropdown { cursor: pointer; border-radius: 6px; border: 1px solid #000; padding: 10px 20px; margin: 20px 0 10px 0;}
.dropdown-title {font-size: 16px; font-weight: 500; }
.address-title span { font-size: 20px; font-weight: 500; border-bottom: 2px solid #000;}
.address-title {margin-bottom: 20px; }
.profile-address {font-size: 14px; font-weight: 500; padding-bottom: 10px;}
.profile-input { border: 1px solid #000; border-radius: 6px; padding: 10px 10px;}
.submit-btn { font-size: 20px; font-weight: 600; font-family: 'Poppins', sans-serif;  margin-top: 20px; padding: 6px 24px; border: 2px solid #fe9c3a; color: #FFFFFF; background-color: #fe9c3a; border-radius: 6px; transition: all 0.5s;}
.submit-btn:hover { color: #fe9c3a; border: 2px solid #fe9c3a; background-color: transparent;}

.order-list { position: fixed; background-color: #fe9c3a; height: 100vh; width: 250px; right: 0; top: 0; bottom: 0; z-index: 1000; transition: all 0.5s; padding: 20px;}
.order-details .overlay {
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    filter: blur(2px);
    -webkit-filter: blur(8px);
    z-index: 990;
    transition: ease 0.5s;
  } */

  .login-img img , .sign-in-img img{
    width: 70%;

  }
  .sign-in-img img{
    width: 55%;

  }

  .sign-in-img{
position: fixed;
top: 10px;
display: flex;
width: 50%;
  }
  .sign-in-img img{}

@media (max-width: 1200px) {
  .sign-in-img{
    display: none;
  }
}