.sign-in { margin-top: 100px; margin-bottom: 50px;}
.sign-in-container {padding: 40px; border: 2px solid #f6f6fb; border-radius: 10px;}
.sign-in-container .sign-in-title { font-family: 'Poppins'; font-size: 28px; font-weight: 700; color: #000000; padding-bottom: 20px;}
.sign-in-input-container { margin-top: 30px;}
.sign-in-input-container .input-title { font-size: 16px; font-family: 'Poppins'; font-weight: 400; line-height: 24px; color: #000000; padding-bottom: 10px;} 
.sign-in-input-container .input-box { font-size: 16px; background-color: #F4F3F3; padding: 10px 12px; font-family: 'Poppins'; font-weight: 400; line-height: 24px; color: #000000; outline: none; border-radius: 4px; margin-bottom: 20px;} 
.sign-in .submit-btn { background-color: #f6f6fb; color: #000; padding: 8px 30px; border-radius: 10px; border: 2px solid #000; transition: ease 0.5s; margin: 20px 0; font-family: 'Libre Baskerville'; font-size: 14px; font-weight: 600;}
.sign-in .submit-btn:hover { border: 2px solid #000; background-color: #000; color: #f6f6fb;}
.sign-in p { font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 400;}
.sign-in p a { font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 400; text-decoration: underline; color: #000; font-weight: 600; cursor: pointer;}
.error-text { color: #DC3545; font-size: 12px !important;}


@media(max-width: 480px) {
    .sign-in-container {padding: 20px;}
    .sign-in-container .sign-in-title {  font-size: 24px; padding-bottom: 0px;}
    .sign-in-input-container { margin-top: 14px;}
    .sign-in-input-container .input-box { font-size: 16px; background-color: #F4F3F3; padding: 8px 6px; margin-bottom: 10px;} 
    .sign-in p { font-size: 12px; font-weight: 400;}
    .sign-in-img img { height: 350px; width: 350px;}
}
.sing-btnns{}
.sing-btnns button{
    padding: 10px 30px;
    background-color: #EFC348;
    color: white;
    border: 1px solid #EFC348;
    border-radius: 10px;
}
