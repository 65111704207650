header {
  position: fixed;
  width: 100% !important;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  padding: 0 12px;
  border-bottom: 0.5px solid #c8cbd9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

}
.main-nav{
    width: 100%;
}
header .logo-list {
  display: flex;
  align-items: center;
  gap: 20px;
}
header .bras-btn {
  font-size: 32px;
  border: 0;
  background-color: transparent;
}
header .list {
  margin-left: unset;
  display: block;
  position: fixed;
  width: 200px;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(252, 241, 204, 1);
  z-index: 1000;
  transition: ease 0.5s;
}
header .list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}
header .list ul li {
  display: block;
  padding: 8px 20px;
}
header .list ul li a {
  font-size: 16px;
  text-decoration: none;
  color: #1f384c;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: ease 0.4s;
}
header .list ul li a:hover {
  color: #000000;
}
header .bras-btn {
  display: block;
  float: right;
}
header .search-box {
  position: relative;
  padding: 20px 0;
}
header .search-box .search-input {
  background-color: #f6f6fb;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  color: #1f384c;
}
header .search-box .search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}
header .social-media-desktop {
  float: right;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
}
header .social-media-mobile {
  float: left;
  margin-right: 0px;
  display: block;
}
header .social-media-desktop a,
.social-media-mobile a {
  padding: 0 15px;
}
header .overlay {
  background-color: rgba(0, 0, 0, 0.66);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 990;
  transition: ease 0.5s;
}

@media (min-width: 1024px) {
  header {
    padding: 0px 76px;
  }
  header .list ul {
    margin-bottom: 0px;
  }
  header .list ul li {
    display: inline-block;
    padding: 15px 10px;
  }
  header .bras-btn {
    display: none;
    float: unset;
  }
  header .list {
    position: static;
    margin-left: 0px;
    width: unset;
    background-color: transparent;
  }
  header .social-media-desktop {
    float: right;
    margin-right: 50px;
    display: inline;
  }
  header .social-media-mobile {
    float: right;
    margin-right: 50px;
    display: none;
  }
}
@media (min-width: 1160px) {
  header .list {
    position: static;
    margin-left: 38px;
    width: unset;
    background-color: transparent;
  }
}
@media (min-width: 1250px) {
  header .list {
    position: static;
    margin-left: 58px;
    width: unset;
    background-color: transparent;
  }
}

.modal-form {
  padding: 20px;
}
.modal-form label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-bottom: 5px;
}
.modal-form input {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 6px 10px;
}
.modal-form select {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 6px 10px;
}
.modal-form textarea {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 6px 10px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown{
  height: 100%;
}
.navicons{
    display: flex;
    justify-content: end;
    align-items: center;
}

@media (max-width: 768px) {
.search-box{
    display: none;

}
header .list {
    padding-top: 50px;
}
header .list ul{
    padding-left: 10px;
    padding-right: 10px;
}
header .list ul li {
    display: block;
    padding: 8px 20px;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 8px;
  }
}
