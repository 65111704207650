.filter-buttons button {
    padding: 10px;
    margin: 5px;
    background-color: #ccc; /* Default color */
    border: none;
    cursor: pointer;
    border-radius: 10px;

  }
  
  .filter-buttons button.active-button-content {
    background-color: #EFC348; /* Highlight color */
    color: white;
  }
  