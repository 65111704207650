.darkBG { background-color: rgba(0, 0, 0, 0.4); width: 100vw; height: 100vh; z-index: 100; top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute; }
.centered { position: fixed; top: 50%; left: 50%; z-index: 101; transform: translate(-50%, -50%);}
.blog-modal { padding: 16px; width: 500px; background: white; color: white; z-index: 150; border-radius: 16px; box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04); }
.modalHeader { height: 50px; background: white; border-top-left-radius: 16px; border-top-right-radius: 16px; }  
.heading { margin: 0;padding: 10px; color: #2c3e50; font-weight: 500; font-size: 18px; text-align: center; }  
.modalContent { padding: 10px; font-size: 14px; color: #2c3e50; text-align: center; }
.modalActions { position: absolute; bottom: 2px; margin-bottom: 10px; width: 100%;}
.add-question { background-color: #f9dd6e; color: #000; font-weight: 600; border: none; border-radius: 8px; padding: 0 20px;}
.disabled-btn { background-color: #f9dd6e87; color: #000000ba; border: none; font-weight: 600; border-radius: 8px; padding: 0 20px;}
.cancel-btn { background-color: #f9dd6e; color: #000; font-weight: 600; border: none; border-radius: 8px; padding: 8px 20px; margin-right: 20px;}
.modal-input-box { padding: 20px 0;}
.modal-input-box .modal-input { padding: 6px 10px 6px 0; border-top: 0; border-left: 0; border-right: 0; border-bottom: 1px solid #000; outline: none; width: 100%;}
.modal-input-box .modal-input:focus { border-bottom: 1px solid #2e69ff;}
